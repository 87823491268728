import '../stylus/home.sass'

/** Carrousel */
import '../libs/OwlCarousel/assets/owl.carousel.min.css'
import '../libs/OwlCarousel/assets/owl.theme.default.min.css'
import '../libs/OwlCarousel/owl.carousel.min.js'

/** Dependencia de boostrap **/
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/offcanvas';

/**
*/

let scroll_flag = false;

$(window).one('scroll', function() {
	if(!scroll_flag){
		initAll();
		scroll_flag = true;
	}
});

$(document).ready(function(){

});

function initAll(){
	$(".solicitarModal").click(function(){
		var infoBoton = $(this).data("boton-info");
		if(infoBoton == 'basico'){
			$("#formModal").addClass("modal-home");
			$("#formModal").modal({
				backdrop: 'static',
			}).modal('show');
			$("#columnaBasica").addClass("col2");
			$("#colFormularioBasico").addClass("col-xl-6");
			$("#columnaBasica").addClass("col-xl-6");
			$("#columnaBasica").addClass("justify-content-center");
			$("#modalReziseBasico").addClass("modal-resize ");
        }else{

			var tarjeta 		= $(this).closest('.tarjeta');
			var tarjetaInfoJSON = tarjeta.attr('data-tarjeta-info');
			var tarjetaInfo 	= JSON.parse(tarjetaInfoJSON);
		
			var valorEsencial 	= formatoNumeroCurrency(tarjetaInfo.valEscencialModel);
			var valorPremium 	= formatoNumeroCurrency(tarjetaInfo.valPremiumModel);
			var valorElite 		= formatoNumeroCurrency(tarjetaInfo.valEliteModel);

			var caja 			= (tarjetaInfo.gearModel == 1) ? x_textos['RNT2006-1'] : x_textos['RNT2007-1'];
			var aire			= (tarjetaInfo.aaModel == 0) ? x_textos['RNT2220-0'] : x_textos['RNT2220-1'];

			$(".img-modelo").attr("src", tarjetaInfo.displaySCBig);
			$(".nombre-modelo").text(tarjetaInfo.nameModel);
			$(".modeloModelo").text(tarjetaInfo.modModel)
			$(".gasolinaModelo").text(tarjetaInfo.combustibleModel);
			$(".cajaModelo").text(caja);
			$(".aireModelo").text(aire);
			$(".kmModelo").text(tarjetaInfo.recorridoModel);
			$(".valorEscencialModelo").text(valorEsencial);
			$(".valorPremiumModelo").text(valorPremium);
			$(".valorEliteModelo").text(valorElite);


			if (tarjetaInfo.infoModel.includes("pedido")) {
				$(".valorPremiumModelo").hide();
				$(".valorEliteModelo").hide();
				$(".valorEscencialModelo").hide();
			}else{
				$(".valorPremiumModelo").show();
				$(".valorEliteModelo").show();
				$(".valorEscencialModelo").show();
			}

			if(infoBoton == 'esencial'||infoBoton =='solinfo'){
                $(".col-esencial").addClass("d-block").removeClass("d-none");
                $(".col-premium").removeClass("d-block").addClass("d-none");
                $(".col-elite").removeClass("d-block").addClass("d-none");
            }
        
            if(infoBoton == 'premium'){
                $(".col-premium").addClass("d-block").removeClass("d-none");
                $(".col-esencial").removeClass("d-block").addClass("d-none");
                $(".col-elite").removeClass("d-block").addClass("d-none");
            }
        
            if(infoBoton == 'elite'){
                $(".col-esencial").removeClass("d-block").addClass("d-none");
                $(".col-premium").removeClass("d-block").addClass("d-none");
                $(".col-elite").addClass("d-block").removeClass("d-none");
            }

			setTimeout(() => {

				if(infoBoton == 'esencial'||infoBoton =='solinfo') $('.show #plan').val('Esencial');
				if(infoBoton == 'premium') $('.show #plan').val('Premium');
				if(infoBoton == 'elite')  $('.show #plan').val('Elite');
	
				$('.show #plan').on('change', function() {
					// Acciones a realizar cuando cambia el valor
					var nuevoValor = $(this).val();				
					switch (nuevoValor) {
						case 'Esencial':
							$(".col-esencial").addClass("d-block").removeClass("d-none");
							$(".col-premium").removeClass("d-block").addClass("d-none");
							$(".col-elite").removeClass("d-block").addClass("d-none");
							break;
						case 'Premium':
							$(".col-premium").addClass("d-block").removeClass("d-none");
							$(".col-esencial").removeClass("d-block").addClass("d-none");
							$(".col-elite").removeClass("d-block").addClass("d-none");
							break;
						case 'Elite':
							$(".col-esencial").removeClass("d-block").addClass("d-none");
							$(".col-premium").removeClass("d-block").addClass("d-none");
							$(".col-elite").addClass("d-block").removeClass("d-none");
							break;
					
						default:
							
							break;
					}
				});
			   }, 200);
	
			if(tarjetaInfo.typeModel==1){
				$("#formModal2").addClass("modal-paso2");
				$("#formModal2").modal({
					backdrop: 'static',
				}).modal('show');
			}
	
			if(tarjetaInfo.typeModel==2){
				$("#formModal3").addClass("modal-paso2");
				$("#formModal3").modal({
					backdrop: 'static',
				}).modal('show');
			}
		}

		// Asocia un controlador de eventos clic al elemento con la clase btn-close
		$(".btn-close").on("click", function() {
			// Cierra el modal utilizando la función modal('hide')
			$("#formModal").modal('hide');
		});

		$('#plan').on('change', function() {
			// Acciones a realizar cuando cambia el valor
			var nuevoValor = $(this).val();
	
			switch (nuevoValor) {
				case 'Esencial':
					$(".col-esencial").addClass("d-block").removeClass("d-none");
					$(".col-premium").removeClass("d-block").addClass("d-none");
					$(".col-elite").removeClass("d-block").addClass("d-none");
					break;
				case 'Premium':
					$(".col-premium").addClass("d-block").removeClass("d-none");
					$(".col-esencial").removeClass("d-block").addClass("d-none");
					$(".col-elite").removeClass("d-block").addClass("d-none");
					break;
				case 'Elite':
					$(".col-esencial").removeClass("d-block").addClass("d-none");
					$(".col-premium").removeClass("d-block").addClass("d-none");
					$(".col-elite").addClass("d-block").removeClass("d-none");
					break;
			
				default:
					
					break;
			}
		});

    });

	initCarouselMedios();
	initCarouselComentarios();
	initCarouselOfertas();
}

function initCarouselComentarios(){
	$('.carousel-comentarios').owlCarousel({
		loop:true,
		nav:true,
		dots: false,
		margin:6,
		navText: [
			'<i class="alk alk-angle-left" id="icon-prev" aria-label="Previo"></i>',
			'<i class="alk alk-angle-right" id="icon-next" aria-label="Siguiente" ></i>',
		],
		responsive:{
			0:{
				items:1
			},
			600:{
				items:3
			},
			1000:{
				items:3
			}
		}
	});

	var ariaLabelIcono = $("#icon-prev").attr("aria-label");
	var ariaLabelIconoNext = $("#icon-next").attr("aria-label");

	// Encuentra el botón padre del icono y asigna el mismo aria-label
	$("#icon-prev").parent(".owl-prev").attr("aria-label", ariaLabelIcono ? ariaLabelIcono :'');
	$("#icon-prev").parent(".owl-prev").attr("role", 'button');
	$("#icon-next").parent(".owl-next").attr("aria-label", ariaLabelIconoNext ? ariaLabelIconoNext :'');
	$("#icon-next").parent(".owl-next").attr("role", 'button');
}

function initCarouselMedios(){
	$('.carousel-medios').owlCarousel({
		loop:true,
		nav:true,
		dots: false,
		margin:6,
		navText: [
			'<i class="alk alk-angle-left" id="icon-prev" aria-label="Previo"  ></i>',
			'<i class="alk alk-angle-right" id="icon-next" aria-label="Siguiente" ></i>',
		],
		responsive:{
			0:{
				items:1
			},
			600:{
				items:3
			},
			1000:{
				items:3
			}
		}
	});

	var ariaLabelIcono = $("#icon-prev").attr("aria-label");
	var ariaLabelIconoNext = $("#icon-next").attr("aria-label");

	// Encuentra el botón padre del icono y asigna el mismo aria-label
	$("#icon-prev").parent(".owl-prev").attr("aria-label", ariaLabelIcono ? ariaLabelIcono :'');
	$("#icon-prev").parent(".owl-prev").attr("role", 'button');
	$("#icon-next").parent(".owl-next").attr("aria-label", ariaLabelIconoNext ? ariaLabelIconoNext :'');
	$("#icon-next").parent(".owl-next").attr("role", 'button');
}

function initCarouselOfertas(){
	$('.carousel-ofertas').owlCarousel({
		loop:true,
		nav:true,
		dots: false,
		margin:6,
		navText: [
			'<i class="alk alk-angle-left" id="icon-prev" aria-label="Previo"  ></i>',
			'<i class="alk alk-angle-right" id="icon-next" aria-label="Siguiente" ></i>',
		],
		responsive:{
			0:{
				items:1
			},
			600:{
				items:2
			},
			1000:{
				items:2
			}
		}
	});

	var ariaLabelIcono = $("#icon-prev").attr("aria-label");
	var ariaLabelIconoNext = $("#icon-next").attr("aria-label");

	// Encuentra el botón padre del icono y asigna el mismo aria-label
	$("#icon-prev").parent(".owl-prev").attr("aria-label", ariaLabelIcono ? ariaLabelIcono :'');
	$("#icon-prev").parent(".owl-prev").attr("role", 'button');
	$("#icon-next").parent(".owl-next").attr("aria-label", ariaLabelIconoNext ? ariaLabelIconoNext :'');
	$("#icon-next").parent(".owl-next").attr("role", 'button');
}
function formatoNumeroCurrency(numero, pref = '$') {
	var formattedNumber = numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	return pref + '\u00A0' + formattedNumber;
}
